.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #514b82;
  -webkit-mask: radial-gradient(circle closest-side at 50% 40%, transparent 94%, black);
  transform-origin: 50% 40%;
  animation: l25 1s infinite linear;
}

@keyframes l25 {
  100% { transform: rotate(1turn); }
}

.relative {
  position: relative;
}

.group:hover .group-hover-opacity {
  opacity: 1;
}

.absolute {
  position: absolute;
}

.bottom-0 {
  bottom: 0;
}

.left-half {
  left: 50%;
}

.transform {
  transform: translateX(-50%);
}

.mb-8 {
  margin-bottom: 2rem;
}

.p-2 {
  padding: 0.5rem; 
}

.text-white {
  color: white;
}

.bg-black {
  background-color: black;
}

.rounded {
  border-radius: 0.25rem; 
}

.opacity-0 {
  opacity: 0;
}

.group-hover-opacity {
  transition: opacity 0.3s;
}
